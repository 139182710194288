* {
	// background-color: rgba(255,0,0,0.2) !important;
}

body {
	line-height: 1.45em;
	overflow-x: hidden;
	font-size: 18px;
	font-family: "Lato", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 26px;
	line-height: 1.45em;
	font-weight: 700;
	font-family: "Lato", sans-serif;
}

h1 {
	font-size: 40px;

	@media #{$mobile} {
		font-size: 24px;
	}
}

h2 {
	font-size: 32px;

	@media #{$mobile} {
		font-size: 18px;
	}
}

a {
	color: $primary;

	&:hover {
		color: darken($primary, 15%);
		text-decoration: none;
	}
}

p {
	margin: 0 0 20px;
}

.mobile-trigger {
	display: none;

	.fa {
		color: #333;
	}

	li {
		display: block !important;
		float: none !important;
	}

	@media #{$mobile} {
		line-height: 30px;
		font-size: 18px;
		color: #333;
		background-color: #fff;
		display: block;
		text-align: center;

		&:focus,
		&:active,
		&:hover {
			color: #333;
		}
	}
}

.mobile {
	display: none;
	background-color: darken($primary, 2%);

	@media #{$mobile} {
		display: block;

		ul {
			list-style: none;
			padding: 0;

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

.navbar-collapse.collapse {
	display: none !important;
}

.navbar-collapse.collapse.in {
	display: block !important;
}

img {
	max-width: 100%;
	height: auto;
}

.center-text { text-align: center; }

.mt15   {    margin-top: 15px; @media #{$tablet} {    margin-top: 30px;} }
.mt20   {    margin-top: 20px; @media #{$tablet} {    margin-top: 30px;} }
.mt30   {    margin-top: 30px; @media #{$tablet} {    margin-top: 30px;} }
.mt45   {    margin-top: 45px; @media #{$tablet} {    margin-top: 30px;} }
.mt60   {    margin-top: 60px; @media #{$tablet} {    margin-top: 30px;} }
.mt90   {    margin-top: 90px; @media #{$tablet} {    margin-top: 30px;} }
.mt120  {    margin-top: 90px; @media #{$tablet} {    margin-top: 30px;} }
.mt150  {    margin-top: 90px; @media #{$tablet} {    margin-top: 30px;} }
.mb15   { margin-bottom: 15px; @media #{$tablet} { margin-bottom: 30px;} }
.mb20   { margin-bottom: 20px; @media #{$tablet} { margin-bottom: 30px;} }
.mb30   { margin-bottom: 30px; @media #{$tablet} { margin-bottom: 30px;} }
.mb40   { margin-bottom: 40px; @media #{$tablet} { margin-bottom: 30px;} }
.mb60   { margin-bottom: 60px; @media #{$tablet} { margin-bottom: 30px;} }
.mb90   { margin-bottom: 90px; @media #{$tablet} { margin-bottom: 30px;} }
.mb120  { margin-bottom: 90px; @media #{$tablet} { margin-bottom: 30px;} }
.mb150  { margin-bottom: 90px; @media #{$tablet} { margin-bottom: 30px;} }

.nopad  { padding: 0; }
.nomarg { margin:  0; }

.h770   { height: 773px; @media #{$desktop} { height: 653px; } @media #{$tablet} { height: 403px; } }
.h385   { height: 385px; @media #{$desktop} { height: 325px; } @media #{$tablet} { height: 400px; } }

.bg-image {
	background-size: cover;
	background-position: center center;
}

.fa {
	color: $primary;
}

.tripad {
	margin-top: 30px;
	margin-bottom: 6px;
}

.image-container {
	background-size: cover;
	background-position: center center;
	padding-top: 25vh;
}

.iframe-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.iframe-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

span.posted {
	padding: 0 0 0 30px;
	font-size: 14px;
	color: #aaa;
}

.iframe-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.iframe-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}