.content-info {
	background-color: $grey;
	padding: 60px 0;
	color: #fff;
	font-size: 15px;

	@media #{$mobile} {
		padding: 30px 0;
		text-align: center;

		.tripad {
			display: inline-block;
			margin-bottom: 30px;
		}
	}

	.mobile-title {
		margin-bottom: 0;
	}

	h2 {
		margin: 0 0 20px;
		font-size: 18px;
	}

	ul {
		margin-bottom: 20px;
	}

	a {
		color: #fff;
	}

	.fa {
		color: #fff;
	}

	ul {
		.fa {
			font-size: 26px;
		}
	}
}

.payments p {
	margin: 0;
}

.video-overlay {
	border: 1px solid #d3d3d3;

	@media #{$tablet} {
		margin-top: 30px;
	}
}

div.socialWidgetContainer {
	display: inline !important;
}

#mc_embed_signup .mc-field-group {
	width: 100% !important;
}

.copyright {
	background: #000000;
	text-align: right;
	line-height: 60px;
	color: #fff;
	font-size: 15px;

	a {
		color: #fff;
	}

	p {
		margin: 0;
	}

	@media #{$mobile} {
		text-align: center;
		line-height: 1.45em;
	}
}

img.mt30 {

	@media #{$mobile} {
		margin-bottom: 30px;
	}
}

#mc_embed_signup {

	form {
		padding: 0 !important;
	}

	label {
		font-size:12px !important;
		font-weight: 300 !important;
	}

	.required.email {
	    display: block !important;
	    width: calc(100% - 170px) !important;
	    padding: 0 15px !important;
	    text-indent: 0% !important;
	    height: 45px !important;
	    border-radius: 0 !important;
	    float: left !important;
	    clear: none !important;
	    border: 0 !important;
	    color: #393939 !important;
	}

    .button {
    	background-color: #393939 !important;
    	border: 2px solid #fff !important;
    	height: 45px !important;
    	width: 166px !important;
    	float: left !important;
    	border-radius: 0 !important;
    	padding: 0 !important;
    	margin: 0 !important;
    	clear: none !important;
    }
}