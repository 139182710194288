/* The main calendar widget.  DIV containing a table. */

div.calendar {
  position: relative;
  z-index: 100;
  width: 226px;
}

.calendar, .calendar table {
  border: 1px solid #cccccc;
  font-size: 11px;
  color: #000;
  cursor: default;
  background: #efefef;
  font-family: arial,verdana,sans-serif;
}

/* Header part -- contains navigation buttons and day names. */

.calendar .button { /* "<<", "<", ">", ">>" buttons have this class */
  text-align: center;    /* They are the navigation buttons */
  padding: 2px;          /* Make the buttons seem like they're pressing */
}

.calendar thead .title { /* This holds the current "month, year" */
  font-weight: bold;      /* Pressing it will take you to the current date */
  text-align: center;
  background: #333333;
  color: #ffffff;
  padding: 2px;
}

.calendar thead .headrow { /* Row <TR> containing navigation buttons */
  background: #dedede;
  color: #000;
}

.calendar thead .name { /* Cells <TD> containing the day names */
  border-bottom: 1px solid #cccccc;
  padding: 2px;
  text-align: center;
  color: #000;
}

.calendar thead .weekend { /* How a weekend day name shows in header */
  color: #dedede;
}

.calendar thead .hilite { /* How do the buttons in header appear when hover */
  background: #bbbbbb;
  color: #000000;
  border: 1px solid #cccccc;
  padding: 1px;
}

.calendar thead .active { /* Active (pressed) buttons in header */
  background: #c77;
  padding: 2px 0px 0px 2px;
}

.calendar thead .daynames { /* Row <TR> containing the day names */
  background: #dddddd;
}

/* The body part -- contains all the days in month. */

.calendar tbody .day { /* Cells <TD> containing month days dates */
  width: 2em;
  text-align: right;
  padding: 2px 4px 2px 2px;
}

.calendar table .wn {
  padding: 2px 3px 2px 2px;
  border-right: 1px solid #cccccc;
  background: #dddddd;
}

.calendar tbody .rowhilite td {
  background: #666666;
  color: #ffffff;
}

.calendar tbody .rowhilite td.wn {
  background: #666666;
  color: #ffffff;
}

.calendar tbody td.hilite { /* Hovered cells <TD> */
  background: #999999;
  padding: 1px 3px 1px 1px;
  border: 1px solid #666666;
}

.calendar tbody td.active { /* Active (pressed) cells <TD> */
  background: #000000;
  color: #ffffff;
  padding: 2px 2px 0px 2px;
}

.calendar tbody td.selected { /* Cell showing today date */
  font-weight: bold;
  border: 1px solid #000;
  padding: 1px 3px 1px 1px;
  background: #000000;
  color: #ffffff;
}

.calendar tbody td.weekend { /* Cells showing weekend days */
  color: #cccccc;
}

.calendar tbody td.today { font-weight: bold; }

.calendar tbody .disabled { color: #999; }

.calendar tbody .emptycell { /* Empty cells (the best is to hide them) */
  visibility: hidden;
}

.calendar tbody .emptyrow { /* Empty row (some months need less than 6 rows) */
  display: none;
}

/* The footer part -- status bar and "Close" button */

.calendar tfoot .footrow { /* The <TR> in footer (only one right now) */
  text-align: center;
  background: #cccccc;
  color: #000;
}

.calendar tfoot .ttip { /* Tooltip (status bar) cell <TD> */
  border-top: 1px solid #cccccc;
  background: #efefef;
  color: #000000;
}

.calendar tfoot .hilite { /* Hover style for buttons in footer */
  background: #666666;
  border: 1px solid #f40;
  padding: 1px;
}

.calendar tfoot .active { /* Active (pressed) style for buttons in footer */
  background: #999999;
  padding: 2px 0px 0px 2px;
}

/* Combo boxes (menus that display months/years for direct selection) */

.combo {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 4em;
  cursor: default;
  border: 1px solid #655;
  background: #ffffff;
  color: #000;
  font-size: smaller;
}

.combo .label {
  width: 100%;
  text-align: center;
}

.combo .hilite {
  background: #fc8;
}

.combo .active {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  background: #efefef;
  font-weight: bold;
}

    body.basket .page-header,
    body.checkout .page-header {
      background-image: url(https://thenormandyhotel.co.uk/wp-content/uploads/2017/09/normandy-gift_voucher_basket_hero-v3.jpg) !important;
    }
    .woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
        float: left;
        width: 48%;
    }
    .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
        float: right;
        width: 48%;
    }
    .page-id-45 .carousel-control {
        color: #c2a45f;
        opacity: 1;
    }
    @media all and (max-width: 768px) {
      .page-id-45 .carousel .item {
        padding: 0 60px;
      }
    }
    .pre-form .btn {
      min-width: 1px;
    }
    .table-head {

    }
    .table-head .pre-title {
      width: 500px;
      padding: 20px 0 0;
      display: block;
      float: left;
      line-height: 50px;
    }
    .table-head .pre-price {
      width: 200px;
      padding: 20px 0 0;
      display: block;
      float: left;
      line-height: 50px;
    }
    .table-head .pre-form {
      width: 270px;
      padding: 20px 0 0;
      display: block;
      float: left;
      line-height: 50px;
      text-align: center;
    }
    .pre-title {
      width: 500px;
      padding: 20px 40px 20px 0 !important;
      display: block;
      float: left;
    }
    .pre-price {
      width: 200px;
      padding: 20px 0;
      display: block;
      float: left;
    }
    .pre-form {
      width: 270px;
      padding: 20px 0;
      display: block;
      float: left;
    }
    .pre-price p {
      margin: 0;
      padding: 0;
      line-height: 40px;
    }
    .pre-title h4 {
      margin: 0;
      padding: 0;
      line-height: 40px;
    }

    .pre-form .input-text {
      width: 60px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #979797;
      padding-left: 10px;
    }

    .shop_product_inner ul {
      border-bottom: 1px solid #979797;
      margin: 0;
    }

    .shop_product_inner ul li {
      padding-left: 0;
      padding-right: 0;
    }
    .pre-container {
      width: 970px;
      padding: 40px 0 0;
    }
    .pre-container h2 {
      margin: 0;
      font-size: 24px;
      color: #393939;
    }
    a.wpmenucart-contents {
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 250px;
        right: 0;
        margin: 0 auto;
        z-index: 999;
        vertical-align: bottom;
        background-color: #be9f56;
        color: #fff;
        box-shadow: 2px 2px 24px 0 rgba(0,0,0,.2);
        border-radius: 15px;
        text-align: center;
        line-height: 70px;
        font-size: 20px;
        text-decoration: none;
        font-family: Zilla Slab,sans-serif;
    }
    .wqo_cart_amount {
      display: none;
    }
    .side-image {
      margin-top: 60px;
      height: 270px;
      width: 720px;
      background-size: cover;
      background-position: center center;
    }
    .side-image.left {
      float: left;
      border-radius: 0 10px 10px 0;
    }
    .side-image.right {
      float: right;
      border-radius: 10px 0 0 10px;
    }
    @media all and (max-width: 1199px) {
      .table-head {

      }
      .table-head .pre-title {
        width: 500px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .table-head .pre-price {
        width: 150px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .table-head .pre-form {
        width: 270px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .pre-title {
        width: 500px;
        padding: 20px 40px 20px 0 !important;
        display: block;
        float: left;
      }
      .pre-price {
        width: 150px;
        padding: 20px 0;
        display: block;
        float: left;
      }
      .pre-form {
        width: 270px;
        padding: 20px 0;
        display: block;
        float: left;
      }
      .pre-price p {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }
      .pre-title h4 {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }

      .pre-form .input-text {
        width: 60px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #979797;
        padding-left: 10px;
      }

      .shop_product_inner ul {
        border-bottom: 1px solid #979797;
        margin: 0;
      }

      .shop_product_inner ul li {
        padding-left: 0;
        padding-right: 0;
      }
      .pre-container {
        width: 940px;
        padding: 40px 0 0;
      }
      .pre-container h2 {
        margin: 0;
        font-size: 24px;
        color: #393939;
      }
    }
    @media all and (max-width: 991px) {
      .table-head {

      }
      .table-head .pre-title {
        width: 380px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .table-head .pre-price {
        width: 100px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .table-head .pre-form {
        width: 230px;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .pre-title {
        width: 380px;
        padding: 20px 40px 20px 0 !important;
        display: block;
        float: left;
      }
      .pre-price {
        width: 100px;
        padding: 20px 0;
        display: block;
        float: left;
      }
      .pre-form {
        width: 230px;
        padding: 20px 0;
        display: block;
        float: left;
      }
      .pre-price p {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }
      .pre-title h4 {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }

      .pre-form .input-text {
        width: 60px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #979797;
        padding-left: 10px;
      }

      .shop_product_inner ul {
        border-bottom: 1px solid #979797;
        margin: 0;
      }

      .shop_product_inner ul li {
        padding-left: 0;
        padding-right: 0;
      }
      .pre-container {
        width: 710px;
        padding: 40px 0 0;
      }
      .pre-container h2 {
        margin: 0;
        font-size: 24px;
        color: #393939;
      }
    }
    @media all and (max-width: 768px) {
      .table-head .pre-title {
        width: 100%;
        padding: 20px 0 0;
        display: block;
        float: left;
        line-height: 50px;
      }
      .table-head .pre-price {
        display: none;
      }
      .table-head .pre-form {
        display: none;
      }
      .pre-title {
        width: 100%;
        padding: 20px 0 0 !important;
        display: block;
        float: left;
      }
      .pre-price {
        width: 25%;
        padding: 10px 0;
        display: block;
        float: left;
      }
      .pre-form {
        width: 75%;
        padding: 10px 0;
        display: block;
        float: left;
      }
      .pre-price p {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }
      .pre-title h4 {
        margin: 0;
        padding: 0;
        line-height: 40px;
      }

      .pre-form .input-text {
        width: 60px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #979797;
        padding-left: 10px;
      }

      .shop_product_inner ul {
        border-bottom: 1px solid #979797;
        margin: 0;
        padding: 0 0 20px;
      }

      .shop_product_inner ul li {
        padding-left: 0;
        padding-right: 0;
      }
      .pre-container {
        width: 100%;
        padding: 40px 0 0;
      }
      .pre-container h2 {
        margin: 0;
        font-size: 24px;
        color: #393939;
      }
      .side-image {
        margin-top: 60px;
        height: 50vw;
        width: 100%;
        background-size: cover;
        background-position: center center;
      }
      .side-image.left {
        float: left;
        border-radius: 10px;
      }
      .side-image.right {
        float: right;
        border-radius: 10px;
      }
    }
    .ui-datepicker-title select {
      color: #333;
    }

.notification-box {
  background-color: #2F2F2F;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.4em;
  margin-top: 30px;

  p {
    margin: 0;
  }

  a {
    color: #fff;
  }
}
.notification-box h2 {
  font-size: 30px;
}