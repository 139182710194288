@media #{$mobile} {
	.open > .dropdown-menu {
		position: relative;
		background: rgba(255,255,255,0.1);
		width: 100%;
		max-width: 100%;
		top: 0 !important;
		padding-left: 0px;
		margin-bottom: 10px;
	}
}

.menu-container {
	box-shadow: inset 0px 5px 5px rgba(0,0,0,0.1);
	text-align: center;
	position: relative;

	.nav-primary {
		text-align: center;

		.navbar-nav {
			float: none;

			@media #{$tablet} {
				margin-top: -4px;
			}

			> li {
				display: inline-block;
				float: none;
				margin-left: -2px;

				> a {
					display: inline-block;
					padding-left:   12px;
					padding-right:  12px;
					padding-top:    10px;
					padding-bottom: 10px;
					border-right: 1px solid #f2ecdd;
					font-size: 14px;
					color: #727272;
					font-weight: 400;
					line-height: 20px;
					height: auto;
					margin-left: -3px;

					&:hover {
						text-decoration: none;
						background-color: $primary;
						color: #fff;
						height: 60px;
						height: 54px;
						margin-bottom: -8px;
						border-radius: 0 0 8px 8px;
						position: relative;
						z-index: 5;
					}

					@media #{$desktop} {
						padding: 15px 10px;
					}

					@media #{$tablet} {
						padding: 13px 8px;
						font-weight: 400;
					}
				}

				ul.dropdown-menu {
					a {
						padding-left:   12px;
						padding-right:  12px;
						padding-top:    6px;
						padding-bottom: 6px;

						&:hover {
							text-decoration: none;
							background-color: #fff;
							height: auto;
							margin-bottom: 0px;
							border-radius: 0px;
							color: $primary;
							position: relative;
							z-index: 5;
						}
					}

					@media #{$mobile} {
						position: relative;
						background: transparent;
					}
				}
			}

			li:last-child {

				a {
					border-right: 0;
				}
			}

			.current-menu-item {
				a {
					display: inline-block;
					padding-left:   15px;
					padding-right:  15px;
					padding-top:    10px;
					padding-bottom: 10px;
					border-right: 1px solid #f2ecdd;
					font-size: 14px;
					font-weight: 700;
					line-height: 20px;
					margin-left: -3px;
					text-decoration: none;
					background-color: $primary;
					color: #fff;
					height: 54px;
					margin-bottom: -8px;
					border-radius: 0 0 8px 8px;
					position: relative;
					z-index: 5;
				}

				

				> ul.dropdown-menu {
					a {
						background: #fff;
						padding-left:   15px;
						padding-right:  15px;
						padding-top:    6px;
						padding-bottom: 6px;
						height: auto;
						border: 0;
						color: #727272;
						font-weight: 400;

						&:hover {
							text-decoration: none;
							background-color: #fff;
							height: auto;
							margin-bottom: 0px;
							border-radius: 0px;
							color: $primary;
							position: relative;
							z-index: 5;
						}
					}
				}
			}
		}
	}
}


.home-sections {

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.15);
	}

	.float-.ottom {
		z-index: 9999;
		position: relative;
		display: inline-block;
	}
}

// .badger {
// 	position: relative;
	
// 	&:after {
// 	    position: absolute;
// 	    top: 0;
// 	    right: 70px;
// 	    content: '';
// 	    background-image: url(../images/awards_tag.png);
// 	    width: 112px;
// 	    height: 130px;

// 	    @media #{$tablet} {
// 	    	right: -15px;
// 	    }

// 		@media #{$mobile} {
// 			display: none;
// 		}
// 	}
// }

.banner {
	padding: 15px 0;

	.mt20 {

		@media #{$mobile} {
			margin-top: 20px;
		}
	}

	.mb20 {

		@media #{$mobile} {
			margin-bottom: 20px;
		}
	}

	@media #{$mobile} {
		height: auto;
		position: sticky;
		top: 0;
		background: #fff;
		z-index: 999999;

		ul.list-inline {
			margin: 0;
		}
	}
}

.brand {
	display: block;

	img {

		@media #{$mobile} {
			width: 130px;
			height: auto;
		}
	}

	@media #{$mobile} {
		text-align: center;
	}
}

.wrap {
	margin-top: -4px;
}

.dropdown-menu {
    left: -3px !important; 
    min-width: 102px !important; 
    border: 0 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    top: 54px !important;

	li {
		display: block !important;
		margin-left: 3px !important;

		&:hover {
		    height: auto;
		    margin-bottom: 0px;
		}
	}
}

.nopad {
	.carousel {

		.item {
			color: #fff;
			height: 630px;
			font-size: 24px;
			line-height: 1.4em;
			background-size: cover;
			background-position: center center;
			text-shadow: 0 2px 2px #000;
			display:none;

			&.active {

				display: block;
			}

			@media #{$mobile} {
				font-size: 16px;
			}

			h2 {
				font-size: 56px;
				font-weight: 700;
				line-height: 1.1em;
				margin-top: 0;

				@media #{$mobile} {
					font-size: 34px;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}

		.col-md-5 {
			position: relative;
		}

		.float-bottom {
			margin: 170px 30px;
			max-width: 570px;
			display: block;

			&.right {
				float: right;
				text-align: right;
			}
		}
	}

	@media #{$tablet} {
		.carousel {

			.item {
				height: 370px;
			}

			.float-bottom {
				margin: 170px 30px 0;
				max-width: 570px;
				display: block;

				&.right {
					float: right;
					text-align: right;
				}
			}
		}
	}

	@media #{$mobile} {
		.carousel {

			.item {
				height: 370px;
			}

			.float-bottom {
				margin: 86px 30px 0;
				max-width: 570px;
				display: block;

				&.right {
					float: right;
					text-align: right;
				}
			}
		}
	}
}

.carousel-control {

	position:absolute;
	z-index: 9999;
}

.carousel-overlay {
	position: relative;
	background-color: #9b1e34;

	h2 {
		font-size: 30px;
	}

	.book-now {
		position: relative;
		margin: auto;
		background-color: #9b1e34;
	    padding: 10px 30px 15px;
		text-align: center;
		max-width: 1140px;
		margin: 3px auto 0;

		span {
			color: #fff;
			font-size: 16px;
			font-weight: 400;
		}

		label {
			color: #fff;
			margin-bottom: 5px !important;
		}

		h3 {
			margin: 20px 0 0px;
			font-size: 16px;
			line-height: 60px;
			padding: 0 10px 0 0;
		}

		.form-block {
			display: block;
			float: left;
			width: calc(100% / 5);
			color: #fff;

			label {
				display: block;
				font-size: 16px;
				font-weight: 400;
				margin: 0;
				line-height: 20px;
				height: 20px;
				text-align: left;
				font-weight: 300;
			}

			input,
			select {
				width: calc(100% - 30px);
				height: 40px;
				border: 0;
				border-radius: 0;
				-webkit-appearance: none;
				padding: 0 15px;
				margin-right: 8px;
				display: block;
				color: #000;
				font-weight: 300;
			}

			input[type="submit"] {
				width: 100% !important;
				background: #89468b !important;
				border: 0 !important;
				color: #fff;
				font-weight: 300;
				text-align: left;
				height: 40px;
			}

			@media #{$tablet} {
				width: 20%;
				margin-right: 1%;

				&.first {
					width: 36%;
					margin-right: 1%;
				}
			}

			@media #{$mobile} {
				width: 49%;
				margin-right: 1%;

				&.first {
					width: 100%;
					margin-right: 0%;
				}

				&.second {
					width: 100%;
					margin-right: 0%;
				}
			}
		}

		@media #{$tablet} {
			width: 100%;
			position: relative;
			margin-top: 0;
			z-index: 9999;
			height: auto;
			margin-top: 3px;
			padding: 30px;

			.form-block {

				input,
				select {
					width: 100%;
					height: 40px;
					border: 0;
					border-radius: 0;
					-webkit-appearance: none;
					padding: 0 15px;
					margin-right: 8px;
					display: block;
					color: #000;
					font-weight: 300;
				}
			}

			.block_last {
				width: 100%;

				.button {
					display: inline-block !important;
					width: 100% !important;
				}
			}
		}
	}
}

.page-header + .carousel-overlay {
	.mb30 {
		display: none;
	}
}

.wrap .carousel-overlay {
	.mb30 {
		display: none;
	}
}