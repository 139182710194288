.single_add_to_cart_button,
.add_to_cart_button,
.checkout-button {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: 0 20px !important;
    margin: 0 !important;
    line-height: 40px !important;
    min-width: 100px !important;
    color: #fff !important;
    font-size: 14px !important;
    text-align: center !important; 

}

.btn {

	&-primary {
	    background-color: $primary;
        border: 1px solid $primary !important;
	    width: auto;
	    border-radius: 0;
	    padding: 0 20px;
	    margin: 0;
	    line-height: 40px;
	    min-width: 150px;
	    color: #fff;

        &:hover {
            background-color: darken($primary, 5%);
            border: 1px solid darken($primary, 5%) !important;
        }
	}
}

.float-bottom {
	.btn {
		margin-top: 30px;
	}
}

input[type="submit"] {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: 0 20px !important;
    margin: 0 !important;
    line-height: 40px !important;
    min-width: 150px !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

label {
	color: $primary;
}

input[type="input"] {
	border: 1px solid $primary;
}